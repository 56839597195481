function trustBox(){
  let __lc = {}
  window.__lc = __lc

  let lc = document.createElement('script')
  lc.type = 'text/javascript'
  lc.async = true
  lc.src = '//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js'
  let s = document.getElementsByTagName('script')[0]
  s.parentNode.insertBefore(lc, s)


}

trustBox()

document.addEventListener('turbo:load', () => window.Trustpilot.Modules.WidgetManagement.findAndApplyWidgets())
document.addEventListener('turbo:before-cache', () => {
  const trustpilotElements = document.querySelectorAll('#tp-widget-wrapper')
  trustpilotElements.forEach(element => element.remove())
})
