import {isGpePackagePage} from '../default/svg_package_zoom'
import  { setTooltip } from '../shared/tooltip'

const hideTooltip = () => {
  let tooltip = document.getElementById('svg-tooltip')
  if (tooltip) {
    tooltip.style.display = 'none'
  }
}

const showTooltip = () => {
  let tooltip = document.getElementById('svg-tooltip')

  if (isGpePackagePage()) {
    tooltip.style.display = 'none'
  } else if (tooltip) {
    tooltip.style.display = 'block'
  }
}

document.addEventListener('turbo:load', () => {
  let elem = document.querySelector('.seating-chart-ticket-table')
  if (elem) {
    elem.addEventListener('mouseover', () => {
      showTooltip()
    })

    elem.addEventListener('mouseout', () => {
      hideTooltip()
    })
  }

  let svg_zoom_elem = document.querySelector('.gpe-package-page')
  if(svg_zoom_elem) {
    svg_zoom_elem.addEventListener('mouseover', () => {
      showTooltip()
    })
  }
})

const tooltipSetter = (tr) => {
  var package_positions = $(tr).find('.ticket-label').attr('data-position')

  if(package_positions) {
    let pkg_positions = package_positions.replace(/'/g, '"')
    pkg_positions = JSON.parse(pkg_positions)

    var seatingChartId = document.getElementsByClassName('seatingChartPackage')[0].getAttribute('data-seating-chart-id')
    pkg_positions = pkg_positions[seatingChartId]

    let keys = Object.keys(pkg_positions)

    if(keys.length > 0) {
      if(keys.length == 1) {
        let tooltips = $('[id^=svg-tooltip]')
        for (let tooltip of tooltips) {
          if(tooltip.id != 'svg-tooltip') {
            $(tooltip).remove()
          }
        }
      }

      let key = keys[0]
      setTooltip(document.getElementById(pkg_positions[key][0]))

      for (let i=0; i<pkg_positions[key].length; i++) {
        let elem = document.getElementById(pkg_positions[key][i])
        elem.style.stroke = '#e10600'
        elem.setAttribute('stroke-width', 3)
      }

      for (let i=1; i<keys.length; i++) {
        key = keys[i]
        setTooltip(document.getElementById(pkg_positions[key][0]), i)
      }
    }
  }
}

$(document)
  .on('mouseover', '.group-package', function () {
    window.tooltipReference = this
    if(window.hoverable)
      tooltipSetter(this)
  })

  .on('mouseleave', '.group-package', function () {
    window.tooltipReference = this
    if(window.hoverable) {
      var package_positions = $(this).find('.ticket-label').attr('data-position')

      if(package_positions) {
        let pkg_positions = package_positions.replace(/'/g, '"')
        pkg_positions = JSON.parse(pkg_positions)

        var seatingChartId = document.getElementsByClassName('seatingChartPackage')[0].getAttribute('data-seating-chart-id')
        pkg_positions = pkg_positions[seatingChartId]

        let keys = Object.keys(pkg_positions)
        let key = keys[0]

        for (let i=0; i<pkg_positions[key].length; i++) {
          let elem = document.getElementById(pkg_positions[key][i])
          elem.setAttribute('stroke-width', 0)
        }
      }
    }
  })
